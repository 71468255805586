import React from 'react'
import { LayoutA } from "../layouts";
import CONFIG from '../template-config'

class PrivacyPolicy extends React.Component {

  render() {
    return (
      <LayoutA>
        <div className="PolicyPage">
          <div className="grid-restrain-narrow text-background">
            <section>
              <div className="title-container">
                <div className="title">Privacy Policy</div>
                <div className="sub-title">Updated on May 24th, 2018.
                </div>
              </div>
              <p>{CONFIG.creator} and its affiliates (collectively, "<b>{CONFIG.creator}</b>", "<b>we</b>",
                "<b>us</b>”)
                is a global organization offering software tools used by developers, testers and operations team members
                to deliver high quality software and applications.
              </p>
              <p>
                <b>European Residents:</b> Please see our <a href="#euro">European Privacy Policy</a> for residents of
                the European Union, the European Economic Area or Switzerland.
              </p>
              <p>This Privacy Policy (“Privacy Policy”) describes our practices regarding information collected through
                our websites ({CONFIG.domain}) and when you use our products and services ({CONFIG.product}), the
                “<b>{CONFIG.creator} Services</b>” or
                “<b>Services</b>”). When you interact with the {CONFIG.creator} Services, you consent to such
                collection, use and sharing of
                information as described in this Privacy Policy and our Terms of Use (whether Hosted or On Premise or
                some combination thereof). If you do not consent to the terms of this Privacy Policy and our Terms of
                Use, do not continue to interact with or use the {CONFIG.creator} Services.
              </p>
            </section>
            <section>
              <h3>Quick Links:</h3>
              <ul>
                <li><a href="#link1">Information We May Collect</a></li>
                <li><a href="#use">Use of Information</a></li>
                <li><a href="#sharing">Sharing of Information</a></li>
                <li><a href="#link4">Cookies and Similar Technologies</a></li>
                <li><a href="#link5">Data Retention</a></li>
                <li><a href="#link6">Choices and Opt-Out</a></li>
                <li><a href="#link7">Cross-Device Tracking</a></li>
                <li><a href="#link8">Employment Opportunities</a></li>
                <li><a href="#link9">Third Party Sites</a></li>
                <li><a href="#link10">Security</a></li>
                <li><a href="#link11">Children</a></li>
                <li><a href="#link12">International Data Transfers</a></li>
                <li><a href="#link13">California Privacy Rights</a></li>
                <li><a href="#link14">Questions, Complaints and Disputes</a></li>
                <li><a href="#link15">Privacy Policy Updates</a></li>
              </ul>
            </section>
            <section>
              <div className="section-light" id="link1">
                <h2>Information We May Collect</h2>
                <p>
                  The {CONFIG.creator} Services gather certain information automatically, some of which may be
                  considered personal
                  information under applicable law.
                </p>
                <p>
                  We may collect and process the following personal data from you for our legitimate interests or the
                  legitimate interests of third parties, as described in this Privacy Policy:
                </p>
                <ul>
                  <li>
                    <b>Information you provide to us:</b> We collect personal data when you voluntarily provide us with
                    such information in the course of using our website or Services. For example, when you register
                    to use our Services, we will collect your name, email address, password, organization
                    information, and billing details. We also collect personal data from you when you subscribe to our
                    newsletter, register for an event, or respond to a survey. If you make an inquiry through our
                    website, or contact us in any other way, we will keep a copy of your communications with us.
                  </li>
                  <li>
                    <b>Information we collect when you do business with us: </b> We may process your personal data
                    when you do business with us – for example, as a customer or prospective customer, or as a
                    vendor, supplier, consultant, or other third party. For example, we may hold your business contact
                    information and financial account information (if any) and other communications you have with us
                    for the purposes of maintaining our business relations with you.
                  </li>
                  <li>
                    <b>Information we automatically collect:</b> We may also collect certain technical information,
                    which
                    in some cases is personal data, by automatic means when you visit our website, such as IP
                    address, browser type and operating system, referring URLs, your use of our website, and other
                    clickstream data. We collect this information automatically through the use of various
                    technologies, such as cookies.
                  </li>
                  <li>
                    <b>Personal information where we act as a data processor: </b>In certain rare cases, we also
                    process personal data on behalf of our customers in the context of supporting our products and
                    services. Where a customer subscribes to our Services for their website, game, or app, they will
                    be the ones who control what event data is collected and stored on our systems. For example,
                    they may ask us to log basic user data (e.g., email address or username), device identifiers, IP
                    addresses, event type, and related source code. In such cases, we are data processors acting in
                    accordance with the instructions of our customers, who are the data controllers. You will need to
                    refer to the privacy policies of our customers to find out more about how such information is
                    handled by them. If you are unable to contact the relevant customer, please contact us at the
                    addresses provided below.
                  </li>
                </ul>
              </div>
            </section>
            <section>
              <div className="section-light" id="use">
                <h2>USE OF INFORMATION</h2>
                <p>
                  {CONFIG.creator} may use the information, including personal information, collected in connection
                  with the {CONFIG.creator} Services for the purpose of providing the Services to our customers,
                  as well as for supporting our business functions, such as fraud prevention, marketing, analytics and
                  legal functions and other legitimate purposes.
                </p>
                <p>
                  To the extent permitted by applicable law and, for customer data, as permitted by our customer
                  agreements,
                  we may use information collected in connection with our Services:
                </p>
                <ul>
                  <li>To operate the {CONFIG.creator} Services and provide support.</li>
                  <li>To fulfill customer requests, such as to create a {CONFIG.creator} Services account or
                    complete customer purchases.
                  </li>
                  <li>To communicate with our customers; to inform customers and users of products, programs, services
                    and promotions.
                  </li>
                  <li>To send customers information regarding the {CONFIG.creator} Services and issues specifically
                    affecting {CONFIG.creator} Services.
                  </li>
                  <li>To respond to reviews, comments, or other feedback provided to us.
                  </li>
                  <li>To support and personalize our Services, websites, mobile services, and advertising.
                  </li>
                  <li>To protect the security and integrity of our Services, content, and our business.
                  </li>
                  <li>To provide support.
                  </li>
                </ul>
                <p>For benchmarking, data analysis, audits, developing new products, enhancing the {CONFIG.creator}
                  Services, facilitating product, software and applications development, improving our services,
                  conducting
                  research, analysis, studies or surveys, identifying usage trends, as well as for other analytics
                  purposes.
                </p>
                <p>
                  To meet our contractual requirements, to comply with applicable legal or regulatory requirements and
                  our policies, and to protect against criminal activity, claims and other liabilities.
                </p>
                <p>
                  For any other lawful purpose for which the information is provided.
                </p>
                <p>
                  Aggregate Information. To the extent permitted by applicable law, we may use, process, transfer, and
                  store any data about individuals and customers or partners in an anonymous (or pseudonymous) and
                  aggregated manner. We may combine personal information with other information, collected online and
                  offline, including information from third party sources. We may also use information in other ways
                  with consent or as permitted by applicable law. By using the {CONFIG.creator} Services, our
                  customers agree that we are hereby licensed to collect, use, share and store anonymized (or
                  pseudonymized)
                  aggregated data collected through the {CONFIG.creator} Services for benchmarking, analytics,
                  A/B testing, metrics, research, reporting, machine learning and other business purposes.
                </p>
                <p>
                  Automated Decisions. To the extent permitted by applicable law, we may collect data in an automated
                  manner and make automated decisions, including using machine learning algorithms, about individual
                  users of the {CONFIG.creator} Services in order to provide or optimize the {CONFIG.creator}
                  Services offered and/or delivered, for security or analytics purposes, and for any other lawful
                  purpose.
                  To the extent permitted by applicable law, we may use automated decisions.
                </p>
              </div>
            </section>
            <section>
              <div className="section-light" id="sharing">
                <h2>SHARING OF INFORMATION</h2>
                <p>To the extent permitted by applicable law, {CONFIG.creator} may share and disclose information,
                  including personal information, as set forth below:
                </p>
                <ul>
                  <li>
                    <b>Customers.</b> We may share information with our customers and their service providers and other
                    platforms that may assist those customers.
                  </li>
                  <li>
                    <b>Affiliates and Agents.</b> We may share information with our affiliates or any business partners
                    or agents acting on our behalf.
                  </li>
                  <li>
                    <b>Service Providers.</b> We may share information with our service providers, agents, vendors and
                    other third parties we use to support and advertise the {CONFIG.creator} Services and our
                    business.
                    We share personal information with such third parties to the extent necessary to provide services
                    to us, and pursuant to binding contractual obligations.
                  </li>
                  <li>
                    <b>Advertising and Marketing.</b> To the extent permitted by applicable law, we may share
                    information
                    with third parties for marketing, advertising, promotions, contests, or other similar purposes.
                    If required by applicable law, we will share such data for advertising and marketing purposes only
                    in an aggregate, anonymous, and de-identified manner.
                  </li>
                  <li>
                    <b>Mergers, Acquisitions, Divestitures.</b> We may share, disclose or transfer information to a
                    buyer,
                    investor, new affiliate, or other successor in the event {CONFIG.creator}, or any affiliate,
                    portion, group or business unit thereof, undergoes a business transition, such as a merger,
                    acquisition
                    , consolidation, reorganization, divestiture, liquidation or dissolution (including bankruptcy),
                    or a sale or other transfer of all or a portion of any assets of {CONFIG.creator} or any
                    affiliates
                    or during steps in contemplation of such activities (e.g., negotiations and due diligence).
                  </li>
                  <li>
                    <b>Law Enforcement and National Security.</b> We may share information with legal, governmental, or
                    judicial authorities, as instructed or required by those authorities or applicable laws, or to
                    comply
                    with any law or directive, judicial or administrative order, legal process or investigation,
                    warrant,
                    subpoena, government request, regulatory request, law enforcement or national security
                    investigation,
                    or as otherwise required or authorized by law.
                  </li>
                  <li>
                    <b>Protection of Rights, Property or Safety.</b> We may also share information if, in our sole
                    discretion, we believe disclosure is necessary or appropriate to protect the rights, property or
                    safety of any person, or if we suspect fraud or other illegal activity, {CONFIG.creator} may
                    also disclose personal information for other purposes or to other
                    third parties when an individual has consented to or requested such disclosure, or where a customer
                    has obtained permission from such individual, or where such disclosure is otherwise legally
                    permitted for legitimate business purposes, and, for customer data, with such customer’s
                    authorization or otherwise in accordance with {CONFIG.creator}’s agreement with such customer.
                  </li>
                </ul>
              </div>
            </section>
            <section>
              <div className="section-light" id="link4">
                <h2>COOKIES AND SIMILAR TECHNOLOGIES</h2>
                <p>
                  We may use cookies and similar technologies to operate and improve the {CONFIG.creator} Services,
                  as well as to simplify our interaction with you. A "cookie" is a unique numeric code that we transfer
                  to your computer so that we can keep track of your interests and/or preferences and recognize you as a
                  return visitor to the websites. We may use cookies, log files, pixel tags, web bugs, web beacons,
                  clear GIFs, Local Storage Objects (LSOs) such as HTML5 and Flash or other similar technologies to
                  collect information about the ways you interact with and use the {CONFIG.creator} Services, to
                  support and enhance features and functionality, to monitor performance, to personalize content and
                  experiences, for marketing and analytics, and for other lawful purposes. We may also engage third
                  party service providers to provide advertisements and promotions on our behalf, or authorize third
                  parties to advertise and market products and services via the {CONFIG.creator} Services.
                </p>
                <p>
                  We may use the following types of cookies and similar technologies:
                </p>
                <ul>
                  <li>
                    <b>Strictly necessary cookies</b> required for the operation of the {CONFIG.creator} Services.
                    They include, for example, cookies that enable you to log into secure areas.
                  </li>
                  <li>
                    <b>Analytical/performance cookies</b> that collect information about how you use
                    the {CONFIG.creator} Services. They allow us to recognize and count the number of visitors and
                    to see how visitors move around our website. This helps us to improve the way our website works.
                    These cookies are sometimes placed by third party providers of web traffic analysis services.
                  </li>
                  <li>
                    <b>Functionality cookies</b> that remember choices you make and recognize you when you return. This
                    enables us to personalize our content, greet you by name and remember your preferences (for example,
                    your choice of language or region).

                  </li>
                  <li>
                    <b>Targeting cookies</b> that collect information about your browsing habits such as the pages you
                    have visited and the links you have followed. We use this information to make our website more
                    relevant to your interests, and, if we enable advertising, to make advertising more relevant to you,
                    as well as to limit the number of times you see an ad. These cookies are usually placed by
                    third-party advertising networks. They remember the other websites that you visit and this
                    information is shared with third-party organizations, for example, advertisers.

                  </li>
                  <li>
                    <b>Most internet browsers accept cookies by default.</b> You can block cookies by activating the
                    setting on your browser that allows you to reject all or some cookies. The help and support area on
                    your internet browser should have instructions on how to block or delete cookies. Some web browsers
                    (including some mobile web browsers) provide settings that allow you to control or reject cookies or
                    to alert you to when a cookie is placed on your computer, tablet or mobile device. Although you are
                    not required to accept cookies, if you block or reject them, you may not have access to all of the
                    features available through the {CONFIG.creator} Services.
                  </li>
                </ul>
                <p>For more information, visit the help page for your web browser or see <a target="_blank"
                                                                                            rel="noopener noreferrer"
                                                                                            href="http://www.allaboutcookies.org">http://www.allaboutcookies.org</a>
                  or visit <a target="_blank" href="www.youronlinechoices.com">www.youronlinechoices.com</a> which has
                  further information about behavioral advertising and online privacy.
                </p>
                <p>We may use third party analytics such as Google Analytics or similar analytics services. For
                  information on how Google processes and collects your information regarding Google Analytics and how
                  you can opt-out, please see <a href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a>.
                </p>
                <p>We may use third-party advertisers to serve or track advertisements on or relating to
                  the {CONFIG.creator} Services. These third parties may use cookies and other tracking and
                  analytical technologies to, among other things, record which ads your browser has loaded and which
                  pages you were viewing when ads were delivered or accessed. Information so collected is subject to the
                  privacy policies of those third parties, which you should carefully review. We have limited or no
                  control over third party use of cookies.
                </p>
              </div>
            </section>
            <section>
              <div className="section-light" id="link5">
                <h2>DATA RETENTION</h2>
                <p>
                  To the extent permitted by applicable law, we may retain information for as long as the account of the
                  customer for whom we collected the information is active, for at least twenty-four (24) months
                  thereafter, or as long as is reasonably necessary to provide the {CONFIG.creator} Services or as
                  needed for other lawful purposes. We may retain cached or archived copies of information. We may
                  retain anonymized or pseudonymized, aggregated data indefinitely, to the extent permitted under
                  applicable law. We may be required to retain some data for a longer period of time because of various
                  laws and regulations or because of contractual obligations. We also will retain information as long as
                  reasonably necessary to comply with our legal obligations, resolve disputes and enforce our
                  agreements.
                </p>
              </div>
            </section>
            <section>
              <div className="section-light" id="link6">
                <h2>CHOICES AND OPT-OUT</h2>
                <p>To the extent required by applicable law, or in our discretion otherwise, we will allow customers and
                  individuals to limit use of personal information. If at any time after providing us with your personal
                  information such information changes or you change your mind about receiving information from us, you
                  may request access to your data or that your data be changed.
                </p>
                <p>If you no longer wish to receive our communications, you may opt-out of receiving them at any time by
                  following the instructions included in each communication.
                </p>
              </div>
            </section>
            <section>
              <div className="section-light" id="link7">
                <h2>CROSS-DEVICE TRACKING</h2>
                <p>When you use your mobile device to interact with us or use the {CONFIG.creator} Services, we may
                  receive information about your mobile device, including a unique identifier for your device. We and
                  our service providers and third parties we collaborate with, including ad networks, may use
                  cross-device/cross-context tracking. For example, you might use multiple browsers on a single device,
                  or use various devices (such as desktops, smartphones, and tablets), which can result in your having
                  multiple accounts or profiles across these various contexts and devices. Cross-device/cross-context
                  technology may be used to connect these various accounts or profiles and the corresponding data from
                  the different contexts and devices.</p>
              </div>
            </section>
            <section>
              <div className="section-light" id="link8">
                <h2>EMPLOYMENT OPPORTUNITIES</h2>
                <p>
                  We provide you with a means for submitting your resume or other personal information through our
                  website or Services for consideration for employment opportunities at {CONFIG.creator}. Personal
                  information received through resume submissions will be kept confidential. We may contact you for
                  additional information to supplement your resume, and we may use your personal information
                  within {CONFIG.creator}, or keep it on file for future use, as we make our hiring decisions.
                </p>
              </div>
            </section>
            <section>
              <div className="section-light" id="link9">
                <h2>THIRD PARTY SITES</h2>
                <p>The {CONFIG.creator} Services may provide links to other websites or resources over
                  which {CONFIG.creator} does not have control (“External Web Sites”). Such links do not constitute
                  an endorsement by {CONFIG.creator} of those External Web Sites. You acknowledge
                  that {CONFIG.creator} is providing these links to you only as a convenience, and further agree
                  that {CONFIG.creator} is not responsible for the content of such External Web Sites. Your use of
                  External Web Sites is subject to the terms of use and privacy policies located on the External Web
                  Sites.
                </p>
              </div>
            </section>
            <section>
              <div className="section-light" id="link10">
                <h2>SECURITY</h2>
                <p>To prevent unauthorized access or disclosure, to maintain data accuracy, and to ensure the
                  appropriate use of data, we employ procedural and technological measures that are reasonably designed
                  to help safeguard the information we collect. {CONFIG.creator} may use encryption, secure socket
                  layer, firewall, password protection and other physical security measures to help prevent unauthorized
                  access to such. {CONFIG.creator} may also place internal restrictions on who in the company may
                  access data to help prevent unauthorized access to such information.
                </p>
                <p>Unfortunately, no data transmission over the Internet or data storage system can be guaranteed to be
                  100% secure. Therefore, despite our efforts, we cannot guarantee its absolute security. We do not
                  warrant or represent that personal information about you will be protected against, loss, misuse, or
                  alteration by third parties.
                </p>
                <p>Where required under applicable law or by contract, we will notify the appropriate parties or
                  individuals of any loss, misuse or alteration of personal information so that such parties or
                  individuals can take the appropriate actions for the due protection of their rights. If such personal
                  information is information of a {CONFIG.creator} customer, we will notify such customer and
                  coordinate with them regarding any required notices to particular individuals.
                </p>
              </div>
            </section>
            <section>
              <div className="section-light" id="link11">
                <h2>CHILDREN</h2>
                <p>We recognize the importance of protecting the privacy and safety of children.
                  The {CONFIG.creator} Services are not intended for children under 16 years of age. We do not
                  knowingly collect personal information from children under 16. Anyone under 16 should not use
                  the {CONFIG.creator} Services. If we learn we have collected or received personal information
                  from a child under 16 without verification of parental consent, we will delete that information. If
                  you believe we might have any information from or about a child under 16, please contact us as set
                  forth below.
                </p>
              </div>
            </section>
            <section>
              <div className="section-light" id="link12">
                <h2>INTERNATIONAL DATA TRANSFERS
                </h2>
                <p>The {CONFIG.creator} Services may be provided using resources and servers located in various
                  countries around the world, including the United States and other countries. Therefore, personal
                  information about individuals or customers may be transferred, processed and stored outside the
                  country where the {CONFIG.creator} Services are used, including to countries outside the European
                  Union (“EU”), European Economic Area (“EEA”) or Switzerland, where the level of data protection may
                  not be deemed adequate by the European Commission.
                </p>
                <p>Canadian residents may have additional rights under Canadian law. Please see the information provided
                  by the <a href="https://www.priv.gc.ca/en/about-the-opc/publications/guide_ind/">Office of the Privacy
                    Commissioner of Canada</a> for additional details. You, and we, confirm that it is our wish that
                  this document and all other related documents be drawn up in English. Vous reconnaissent avoir exigé
                  la rédaction en anglais du présent document ainsi que tous les documents qui s'y rattachent.
                </p>
              </div>
            </section>
            <section>
              <div className="section-light" id="link13">
                <h2>CALIFORNIA PRIVACY RIGHTS
                </h2>
                <p>Under California’s “Shine the Light” law, California residents who provide personal information in
                  obtaining products or services for personal, family or household use may be entitled to request and
                  obtain from us, once per calendar year, information about customer information we have shared, if any,
                  with other businesses for such other businesses’ own direct marketing uses. If applicable, this
                  information would include the categories of resident information and the names and addresses of those
                  businesses with which we shared such resident information for the immediately prior calendar year. To
                  obtain this information, please contact us as indicated below. Please include sufficient personal
                  identification information so that we can process the request, including that you are a California
                  resident.
                </p>
              </div>
            </section>
            <section>
              <div className="section-light" id="link14">
                <h2>QUESTIONS, COMPLAINTS AND DISPUTES</h2>
                <p>If you have any questions about this Privacy Policy, or our collection, use, sharing or storage of
                  information about you, you can contact us by email at <a href="mailto:XXX">XXX.com</a>,
                  or write to the following address:
                </p>
                <p><b>{CONFIG.creator} LLC - P.O. Box 442533 - Lawrence, KS - 66044</b></p>
                <p>CLASS ACTION WAIVER. YOU AND WE AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR OR
                  OUR INDIVIDUAL CAPACITY AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR
                  REPRESENTATIVE PROCEEDING.
                </p>
                <p>DISPUTE RESOLUTION. Except as otherwise expressly provided above, or in our European privacy policy
                  or otherwise required by applicable law, you agree that the exclusive jurisdiction of any actions
                  arising out of, relating to, or in any way connected with, this privacy policy, shall be in the state
                  or federal courts, as applicable, located in the City of Lawrence or Douglas County, Kansas. Any
                  dispute, controversy, or claim arising out of or relating to this privacy policy or the collection,
                  use, storage or transfer of any of your information or data, including, but not limited to, the
                  arbitrability of the matter or the formation, interpretation, scope, applicability, termination, or
                  breach of this privacy policy, shall be referred to and finally determined by arbitration in
                  accordance with the JAMS Streamlined Arbitration Rules and Procedures, or JAMS International
                  Arbitration Rules, if the matter is deemed "international" within the meaning of that term as defined
                  in the JAMS International Arbitration Rules. The arbitration shall be administered by JAMS, shall take
                  place before a sole arbitrator, and shall be conducted in Lawrence, Kansas. If the JAMS International
                  Arbitration Rules apply, the language to be used in the arbitral proceedings will be English. Judgment
                  upon the arbitral award may be entered by any court having jurisdiction. This section shall apply to
                  and require arbitration of all disputes, controversies, and claims, regardless of whether such
                  disputes, controversies, or claims concern a single individual, entity, or other person, multiple
                  individuals, entities, or other people, or classes of individuals, entities, or other people. You
                  hereby consent to receive service of process by electronic means or social media to the extent allowed
                  by the applicable court. This constitutes express agreement of the parties regarding your consent
                  pursuant to United States Federal Rule of Civil Procedure 5(b)(2)(E) and any applicable state (or
                  other jurisdiction) equivalent.
                </p>
              </div>
            </section>
            <section>
              <div className="section-light" id="link15">
                <h2>PRIVACY POLICY UPDATES</h2>
                <p>{CONFIG.creator} may update this Privacy Policy from time to time in our sole discretion to
                  reflect changes to our information and privacy practices. {CONFIG.creator} will post any updated
                  Privacy Policy on this page or in the {CONFIG.creator} Services, or with any notice to individual
                  users if required by applicable law. Continued use of the {CONFIG.creator} Services after any
                  such modifications constitutes acceptance to any such modified Privacy Policy. {CONFIG.creator}
                  encourages you to review this Privacy Policy regularly for any changes. The date of last revision is
                  shown at the “Last Updated” legend at the top of this page.
                </p>
              </div>
            </section>
            <section>
              <div className="section-light" id="euro">
                <h2>EUROPEAN PRIVACY POLICY</h2>
                <p>The following European Privacy Policy applies to residents of the European Union, the European
                  Economic Area and Switzerland.
                </p>
                <p><b>Personal Data and Processing. </b> For the purposes of this European Privacy Policy:
                </p>
                <p>
                  <i>“Personal Data”</i> means any information relating to an identified or identifiable natural person
                  who can be identified, directly or indirectly, in particular by reference to an identifier such as a
                  name, an identification number, location data, an online identifier or to one or more factors specific
                  to the physical, physiological, genetic, mental, economic, cultural or social identity of such natural
                  person; and
                </p>
                <p>
                  <i>“Processing”</i> means any operation or set of operations which is performed on Personal Data or
                  on sets of Personal Data, whether or not by automated means, such as collection, recording,
                  organization,
                  structuring, storage, adaptation or alteration, retrieval, consultation, use, disclosure by
                  transmission,
                  dissemination or otherwise making available, alignment or combination, restriction, erasure or
                  destruction.
                </p>
                <p>
                  <b>Purposes and Legal Bases for Processing Personal Data.</b> {CONFIG.creator} processes data
                  for the purposes as set forth in our Privacy Policy, including to provide the {CONFIG.creator}
                  Services and as explained in the <a href="use">Use of Information</a> and <a href="sharing">Sharing of
                  Information</a> sections. To fulfill
                  these purposes, {CONFIG.creator} may access data, including Personal Data, to provide
                  the {CONFIG.creator} Services, to correct and address technical, service or security problems, or
                  in response to contractual requirements. Please see our {CONFIG.creator} Privacy Policy
                  Information
                  We Collect and Data Retention sections for additional details on how we collect, use, disclose and
                  share data, make automated decisions, and retain data, including Personal Data, about individual users
                  or customers.
                </p>
                <p>Our legal bases for the processing of Personal Data are: (i) consent or (ii) any other applicable
                  legal bases, such as our legitimate interest in engaging in commerce, offering products and services
                  of value to the customers of the {CONFIG.creator} Services, preventing fraud, ensuring
                  information
                  and network security, direct marketing and advertising, and complying with industry practices.
                </p>
                <p><b>Additional Rights for European Residents.</b> As a resident of the EU or a country following
                  substantially similar legislation regarding the protection of Personal Data, individuals may have
                  one or more of the following additional rights:</p>
                <ul>
                  <li><b>Access.</b> To request a copy of the Personal Data we have collected about you by contacting
                    us.
                  </li>
                  <li><b>Rectification & Erasure.</b>To request that we rectify or delete any of the Personal
                    Data about you that is incomplete, incorrect, unnecessary or outdated.
                  </li>
                  <li><b>Objection.</b> To object, at any time, to Personal Data about you being Processed for direct
                    marketing purposes.
                  </li>
                  <li><b>Restriction of Processing.</b> To request restriction of Processing of Personal Data about you
                    for certain reasons, such as, for example, if you consider Personal Data about you collected by us
                    to be inaccurate or you have objected to the Processing and the existence of legitimate grounds for
                    Processing is still under consideration.
                  </li>
                  <li><b>Data Portability.</b> To request and receive the Personal Data we have collected about you in a
                    commonly used and machine-readable form.
                  </li>
                  <li><b>Right to Withdraw Consent.</b> If Personal Data about you is processed solely based on your
                    consent and not for any other legitimate interest, to withdraw your consent at any time, without
                    affecting the lawfulness of our Processing based on such consent before it was withdrawn, including
                    processing related to existing contracts for our products and services.
                  </li>
                  <li><b>Right to Lodge a Complaint with a DPA.</b> If you believe our Processing of Personal Data about
                    you is inconsistent with the applicable data protection laws, to lodge a complaint with your local
                    supervisory data protection authority (“DPA”).
                  </li>
                </ul>
                <p>To exercise any of the above listed rights, please contact us as at <a
                  href={`mailto:${CONFIG.supportEmail}`}>{CONFIG.supportEmail}</a> and provide sufficient details so that we
                  can respond appropriately. We will process any requests in accordance
                  with applicable law and within a reasonable period of time. We may need to verify the identity of
                  the individual submitting a request before we can address such request. If the request relates to
                  data our customers collect and process through the {CONFIG.creator} Services, we will refer the
                  request to that customer and will support them in responding to the request. For {CONFIG.creator}
                  customers, certain information may be reviewed, corrected and updated by logging into
                  the {CONFIG.creator}
                  Services account and editing the profile information.
                </p>
                <p><b>Compelled Disclosures.</b> {CONFIG.creator} may be required to disclose Personal Data in
                  response to lawful requests by public authorities, including to meet national security or law
                  enforcement
                  requirements.
                </p>
                <p><b>Data Protection Contact.</b> Unless otherwise specified, the data controller of personal data
                  uploaded
                  to the {CONFIG.creator} Services is the {CONFIG.creator} customer for whom such Services are
                  provided and {CONFIG.creator} is the processor of such data for such customer. In certain cases,
                  {CONFIG.creator} may also be the controller of aggregated, anonymous or pseudonymous data
                  relating
                  to the {CONFIG.creator} Services. Our Data Protection Contact for the personal information
                  collected
                  in connection with the {CONFIG.creator} Services is:
                </p>
                <p>
                  Attn: General Counsel, Legal Dept.<br/>
                  {CONFIG.creator} LLC<br/>
                  P.O. Box 442533<br/>
                  Lawrence, KS 66044<br/>
                  Phone: +1 (785) 813-2871
                </p>
              </div>
            </section>
          </div>
        </div>
      </LayoutA>
    )
  }

}

export default PrivacyPolicy;
